import React from "react"
import { IoMdCheckmarkCircle } from "react-icons/io"
import { Link } from "gatsby"

const SuccessScreen = () => {
  return (
    <div className="h-full flex flex-col justify-start items-center">
      <IoMdCheckmarkCircle className="text-green-400 lg:mt-12" size={240} />
      <span className="font-extrabold text-gray-700 text-xl w-auto lg:w-128 text-center">
        ¡Tu información se ha enviado exitosamente, pronto te estaremos
        contactando!
      </span>
      <Link to="/" className="mt-auto link">
        Volver al inicio
      </Link>
    </div>
  )
}

export default SuccessScreen
