import React from "react"
import BaseInputField from "./BaseInputField"

type Props = {
  name: string
  placeholder: string
  onChange(e: React.ChangeEvent<HTMLInputElement>): void
  onBlur(e: React.FocusEvent<HTMLInputElement>): void
  type?: string
  className?: string
  value?: string
  invalidMessage?: string
  isInvalid?: boolean
}
const InputField: React.FC<Props> = ({
  name,
  type,
  placeholder,
  className,
  value,
  onChange,
  invalidMessage,
  onBlur,
  isInvalid,
}) => {
  const invalidClasses = "border border-red-500"
  return (
    <BaseInputField labelText={placeholder} className={className} name={name}>
      <input
        className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
          isInvalid ? invalidClasses : ""
        }`}
        type={type || "text"}
        name={name}
        id={name}
        placeholder={placeholder}
        value={value || ""}
        onChange={onChange}
        onBlur={onBlur}
      />
      {isInvalid && (
        <label className="text-sm text-red-500">{invalidMessage}</label>
      )}
    </BaseInputField>
  )
}

export default InputField
