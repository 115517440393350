import React from "react"
import BaseInputField from "./BaseInputField"

type Props = {
  name: string
  placeholder: string
  onChange(e: React.ChangeEvent<HTMLTextAreaElement>): void
  onBlur(e: React.FocusEvent<HTMLTextAreaElement>): void
  className?: string
  rows?: number
  value?: string
  invalidMessage?: string
  isInvalid?: boolean
}
const TextArea: React.FC<Props> = ({
  name,
  placeholder,
  className,
  rows,
  value,
  onChange,
  onBlur,
  isInvalid,
  invalidMessage,
}) => {
  const invalidClasses = "border border-red-500"

  return (
    <BaseInputField labelText={placeholder} className={className} name={name}>
      <textarea
        className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
          isInvalid ? invalidClasses : ""
        }`}
        name={name}
        id={name}
        placeholder={placeholder}
        rows={rows || 8}
        value={value || ""}
        onChange={onChange}
        onBlur={onBlur}
      />
      {isInvalid && (
        <label className="text-sm text-red-500">{invalidMessage}</label>
      )}
    </BaseInputField>
  )
}

export default TextArea
