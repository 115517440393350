import React from "react"

type Props = {
  labelText: string
  children: React.ReactNode
  name: string
  className?: string
}
const BaseInputField: React.FC<Props> = ({
  className,
  labelText,
  children,
  name,
}) => {
  return (
    <div className={className}>
      <label
        className="block text-gray-700 text-sm font-bold mb-1"
        htmlFor={name}
      >
        {labelText}
      </label>
      {children}
    </div>
  )
}

export default BaseInputField
