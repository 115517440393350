import React from "react"

type Props = {
  children: React.ReactNode
  onSubmit?(): any
  disabled?: boolean
  type?: "submit" | "button" | "reset"
  className?: string
}
const Button: React.FC<Props> = ({ children, className, ...rest }) => {
  return (
    <button
      type={rest.type || "button"}
      {...rest}
      className={`bg-primary-500 text-white rounded-md px-8 py-1 disabled:opacity-50 disabled:cursor-default hover:bg-primary-700 active:bg-primary-900 ${className}`}
    >
      {children}
    </button>
  )
}

export default Button
