import React, { useState } from "react"
import SEO from "../components/Seo"
import Title from "../components/Title/index"
import ContactForm from "../components/ContactForm/index"
import SuccessScreen from "../components/SuccessScreen/index"

const Contacto = () => {
  const [submitted, setSubmitted] = useState(false)
  return (
    <div className="container h-full">
      <SEO title="Contacto" socialTitle="Tooth Club | Contacto" />
      {submitted ? (
        <SuccessScreen />
      ) : (
        <>
          <Title className="mb-2">Contacto</Title>
          <ContactForm setSubmitted={setSubmitted} />
        </>
      )}
    </div>
  )
}

export default Contacto
