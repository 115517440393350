import React from "react"
import InputField from "../form/InputField"
import TextArea from "../form/TextArea"
import Button from "../Button/index"
import { Formik, Form } from "formik"
import * as Yup from "yup"

type FormValues = {
  name: string
  email: string
  message: string
  subject: string
  honeypot?: string
}

const initialValues: FormValues = {
  name: "",
  email: "",
  message: "",
  subject: "",
  honeypot: "",
}

Yup.setLocale({
  string: {
    email: "Debe proporcionar un correo electrónico válido",
    min: "¡Demasiado corto! Debe ser de al menos ${min} caracteres",
    max: "¡Demasiado largo! Debe ser de máximo ${max} caracteres",
  },
  mixed: {
    required: "Por favor, llena este campo",
  },
})
const validationSchema: Yup.ObjectSchema<
  object & FormValues
> = Yup.object().shape({
  name: Yup.string().required().min(3).max(40),
  email: Yup.string().email().required(),
  message: Yup.string().required().min(40).max(600),
  subject: Yup.string().required().min(5).max(78),
})

type Props = {
  setSubmitted: React.Dispatch<React.SetStateAction<boolean>>
}
const ContactForm: React.FC<Props> = ({ setSubmitted }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}
      initialErrors={{ subject: "Error inicial" }}
      onSubmit={async (values, formikBag) => {
        formikBag.setSubmitting(true)
        await fetch("https://api.staticforms.xyz/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...values,
            accessKey: process.env.GATSBY_STATIC_FORM_KEY,
            replyTo: "@",
          }),
        })

        setSubmitted(true)
      }}
    >
      {({
        values,
        handleChange,
        errors,
        isValid,
        dirty,
        isSubmitting,
        touched,
        handleBlur,
      }) => (
        <Form autoComplete="off">
          <InputField
            name="subject"
            value={values.subject}
            placeholder="Asunto"
            className="contact-form-spacing"
            onChange={handleChange}
            isInvalid={touched.subject && !!errors.subject}
            invalidMessage={errors.subject}
            onBlur={handleBlur}
          />
          <div className="grid col-gap-6 grid-cols-1 lg:grid-cols-2">
            <InputField
              name="name"
              value={values.name}
              placeholder="Nombre"
              onChange={handleChange}
              className="contact-form-spacing"
              isInvalid={touched.name && !!errors.name}
              invalidMessage={errors.name}
              onBlur={handleBlur}
            />
            <InputField
              name="email"
              value={values.email}
              placeholder="Correo Electrónico"
              onChange={handleChange}
              className="contact-form-spacing"
              isInvalid={touched.email && !!errors.email}
              invalidMessage={errors.email}
              onBlur={handleBlur}
            />
          </div>
          <TextArea
            name="message"
            value={values.message}
            placeholder="Mensaje"
            onChange={handleChange}
            className="contact-form-spacing"
            isInvalid={touched.message && !!errors.message}
            onBlur={handleBlur}
            invalidMessage={errors.message}
          />
          <input type="hidden" name="replyTo" value="@" />
          <input
            type="hidden"
            name="honeypot"
            className="hidden"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Button
            type="submit"
            className="lg:px-12 px-0 w-full lg:w-auto"
            disabled={!dirty || !isValid || isSubmitting}
          >
            Enviar
          </Button>
        </Form>
      )}
    </Formik>
  )
}
export default ContactForm
